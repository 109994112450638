class StatusHandler {
    constructor(onUpdate, onError, onSuccess) {
        this.onUpdate = onUpdate;
        this.onError = onError;
        this.onSuccess = onSuccess;
    }

    onUpdate = null;
    onSuccess = null;
    onError = null;

    updateStatus(text) {
        this.onUpdate(text);
    }

    showError(text) {
        this.onError(text);
    }

    onSuccess(text) {
        let msg = "";
        if(text == null || text ===""){
            msg = "Aktion war erfolgreich!"
        }
        this.onSuccess(msg);
    }
}

export default StatusHandler;