import React, {useEffect} from "react";

import AuthenticationService from "./services/authentication.service";
import {useLocation, useNavigate} from "react-router";
import useFetchData from "./hooks/useFetchData";
import UserListDataGrid from "./UserListDataGrid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {useConfirm} from "material-ui-confirm";

const UserListView = () => {
    const navigate = useNavigate(),
        location = useLocation(),
        confirm = useConfirm();

    const
        {
            data: entries,
            loading: loadingAllUsers,
            setRefetch: setRefetch,
        } = useFetchData({url: '/api/allUsers'});

    const confirmDelete = (id, name) => {
        confirm({
            title: 'Löschen Bestätigen',
            description: `Möchten sie ${name} wirklich löschen?`,
            confirmationText: "Ja",
            cancellationText: "Abbrechen",
        })
            .then(() => deleteEntry(id))
            .catch(() => console.log("Deletion cancelled."));
    }

    const deleteEntry = (id) => {
        let token = AuthenticationService.getCurrentUser().jwt;
        fetch("/api/deleteUser/" + id, {
            method: 'DELETE',
            headers: {'Authorization': 'Bearer ' + token}
        }).then(
            response => {
                if (response != null && response.ok) {
                    setRefetch(true);
                    return;
                }
                console.error("Request zum Löschen des Eintrages war nicht erfolgreich!");
            });
    }


    useEffect(() => {
        if (!AuthenticationService.getCurrentUser()) {
            navigate("/login", {state: ""});
        }

    }, [location.key]);

    return (
        <Container component="main" maxWidth="md" sx={{height: "80vh"}}>
            <Typography component="h1" variant="h5" align="center" sx={{my: 1}}>
                Übersicht Benutzer
            </Typography>
            <UserListDataGrid
                rows={entries}
                deleteEntry={(id, name) => confirmDelete(id, name)}
                loading={loadingAllUsers}
            />
        </Container>
    )
}

export default UserListView;