import React, {useState} from "react";
import AuthenticationService from "./services/authentication.service";
import {useNavigate} from "react-router";
import {Alert, Snackbar, useTheme} from "@mui/material";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import GavelIcon from '@mui/icons-material/Gavel';
import useFetchData from "./hooks/useFetchData";

const ProductDetails = (props) => {
    const navigate = useNavigate();
    const origProductName = props.product.name;
    const origProductValue = props.product.value;
    const origProductValidFrom = props.product.validFrom;

    const [product, setProduct] = useState(props.product);
    const [validFromError, setValidFromError] = useState();
    const [nameError, setNameError] = useState();
    const [amountError, setAmountError] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [open, setOpen] = useState(false);

    const {
        data: produkte,
        loading: loadingProdukte,
        error: errorLoadingProdukte
    } = useFetchData({url: '/api/allProducts'});

    if (errorLoadingProdukte) {
        setOpen(true);
        setErrorMessage(errorLoadingProdukte);
    }

    const doLowAndTrim = (value) => {
        if (!value) {
            return value;
        }
        return value.toLowerCase().trim();
    }

    const equalStrings = (value1, value2) => {
        return doLowAndTrim(value1) === doLowAndTrim(value2);
    }

    const validateProductName = (productName) => {
        if (!productName) {
            return false;
        }
        if (product.id === 0) {
            const productExists = produkte.filter(produkt => equalStrings(produkt.name, productName));
            return productExists.length === 0;
        } else if (product.name !== productName) {
            const productExists = produkte.filter(p =>
                equalStrings(p.name, productName) && !equalStrings(productName, origProductName));
            return productExists.length === 0;
        }

        return true;
    }

    const validateAmount = (amount) => {
        return amount >= 0;
    }
    const validateValidFrom = (validFrom) => {
        const userEntered = new Date(validFrom);
        const now = new Date();
        const today = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
        return userEntered.getTime() >= today.getTime();
    }


    const handleFormChange = (event) => {
        const type = event.target.type;
        const name = event.target.name;
        const value = type === "checkbox" ? event.target.checked : event.target.value;

        setProduct(oldState => ({...oldState, [name]: value}));
    }

    const isValid = () => {
        const errorList = [];
        if (nameError) {
            errorList.push(nameError);
        }
        if (validFromError) {
            errorList.push(validFromError);
        }
        if (amountError) {
            errorList.push(amountError);
        }

        if (errorList.length > 0) {
            const errorMessage = errorList.reduce((message, errorMessage) => message + " " + errorMessage);
            setErrorMessage(errorMessage);
        }

        return errorList.length === 0;
    }

    const hasChanged = () => {
        return origProductName !== product.name
            || Number(origProductValue) !== Number(product.value)
            || origProductValidFrom !== product.validFrom;
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!isValid()) {
            setOpen(true);
            return;
        }

        if (Number(product.value) > 0) {
            product.value = Number(product.value) * -1;
        }
        const data = JSON.stringify(product);

        let token = AuthenticationService.getCurrentUser().jwt;

        const requestOptions = {
            credentials: 'same-origin',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: data
        };

        let url = product.id === 0 ? '/api/newProduct' : '/api/updateProduct';

        console.log("saveProduct: " + url + " id: " + product.id);
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                navigate("/products", {replace: true});
            })
            .catch(error => console.error(error));

    }

    const theme = useTheme();

    const themeWithLocale = React.useMemo(
        () => createTheme(theme, "deDE"),
        [theme],
    );

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <ThemeProvider theme={themeWithLocale}>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{m: 1, bgcolor: '#0d6efd'}}>
                        <GavelIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Produkt
                    </Typography>
                    <Box component="form" sx={{mt: 1}} onSubmit={handleSubmit}>
                        <TextField
                            error={!!nameError}
                            helperText={nameError}
                            margin="normal"
                            fullWidth
                            name="name"
                            label="Name"
                            type="text"
                            id="name"
                            focused={true}
                            value={product.name}
                            onChange={(e) => {
                                if (!validateProductName(e.target.value)) {
                                    if (e.target.value.length === 0) {
                                        setNameError("Der Produktname muss gefüllt sein!");
                                    } else {
                                        setNameError("Dieser Produktnamen wird bereits verwendet!");
                                    }
                                } else {
                                    setNameError("");
                                }
                                handleFormChange(e);
                            }
                            }
                        />
                        <TextField
                            error={!!amountError}
                            helperText={amountError}
                            fullWidth
                            required
                            name="value"
                            label="Betrag: "
                            type="number"
                            InputProps={{
                                inputProps: {min: 0}
                            }}
                            sx={{mt: 3}}
                            value={product.value}
                            onChange={(e) => {
                                if (!validateAmount(e.target.value)) {
                                    setAmountError("Der Betrag muss größer 0 sein!")
                                } else {
                                    setAmountError("");
                                }
                                handleFormChange(e);
                            }}
                        />
                        <TextField
                            error={!!validFromError}
                            helperText={validFromError}
                            margin="normal"
                            required
                            fullWidth
                            type="date"
                            id="date"
                            label="Gültig ab"
                            name="validFrom"
                            autoComplete="date"
                            value={product.validFrom}
                            onChange={(e) => {
                                if (!validateValidFrom(e.target.value)) {
                                    setValidFromError("Das Gültig Ab Datum darf nicht in der Vergangenheit liegen!");
                                } else {
                                    setValidFromError("");
                                    handleFormChange(e);
                                }
                            }
                            }
                        />
                        <Box display="flex" justifyContent="center" mt={1}>
                            <Button
                                type="submit"
                                disabled={!hasChanged()}
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2, mr: 2}}
                            >
                                Speichern
                            </Button>
                            <Button
                                type="reset"
                                variant="outlined"
                                fullWidth
                                sx={{mt: 3, mb: 2, ml: 2}}
                                onClick={() => navigate("/products")}
                            >
                                Abbrechen
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </ThemeProvider>
            {errorMessage && (
                <Snackbar open={open} anchorOrigin={{vertical: 'top', horizontal: 'center'}} autoHideDuration={6000}
                          onClose={() => {
                              setErrorMessage(null);
                              setOpen(false);
                          }}>
                    <Alert severity="error" sx={{width: '100%'}}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            )}
        </Container>
    );
}

export default ProductDetails;
