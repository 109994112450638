import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {MenuItem} from "@mui/material";
import TextField from "@mui/material/TextField";
import ReportBalanceUsers from "./ReportBalanceUsers";
import Avatar from "@mui/material/Avatar";
import InsertChartIcon from '@mui/icons-material/InsertChart';
import Typography from "@mui/material/Typography";
import ReportMonthlyBalancePerYear from "./ReportMonthlyBalancePerYear";

export default function Reporting(props) {
    const [report, setReport] = React.useState("0");

    const handleReportChange = (e) => {
        setReport(e.target.value);
    }

    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{m: 1, bgcolor: '#0d6efd'}}>
                    <InsertChartIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Auswertungen
                </Typography>
                <TextField
                    select
                    required
                    label="Auswertung"
                    sx={{mt: 2}}
                    name="report"
                    id="report"
                    fullWidth
                    SelectProps={{
                        variant: "outlined",
                        value: report,
                        onChange: (e) => handleReportChange(e)
                    }}
                >
                    <MenuItem key={0} value={"0"}>
                        Bitte Auswertung auswählen
                    </MenuItem>
                    {/*<MenuItem key={1} value={"1"}>*/}
                    {/*    Umsatz je Monat und Person*/}
                    {/*</MenuItem>*/}
                    {/*<MenuItem key={2} value={"2"}>*/}
                    {/*    Umsatz je Monat und Produkt*/}
                    {/*</MenuItem>*/}
                    <MenuItem key={3} value={"3"}>
                        Saldo je Benutzer
                    </MenuItem>
                    {/*<MenuItem key={4} value={"4"}>*/}
                    {/*    Produktanteile an Umsatz*/}
                    {/*</MenuItem>*/}
                    <MenuItem key={5} value={"5"}>
                        Umsatz je Monat
                    </MenuItem>
                </TextField>

                {/*{report === "1" && (<ReportMonthlyBalance/>)}*/}
                {/*{report === "2" && (<ReportMonthlyBalanceProduct/>)}*/}
                {report === "3" && (<ReportBalanceUsers/>)}
                {/*{report === "4" && (<ReportPercentageOfBalance/>)}*/}
                {report === "5" && (<ReportMonthlyBalancePerYear/>)}
            </Box>
        </Container>
    )
}
