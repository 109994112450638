import {useParams} from "react-router";
import {Skeleton} from "@mui/material";
import React from "react";
import useFetchData from "./hooks/useFetchData";
import AccountEntryDetails from "./AccountEntryDetails";

const AmountEdit = (props) => {
    const {id: paramsAccountID} = useParams();

    console.log("ParamsId: " + paramsAccountID);

    const {
        data: entry,
        loading: loadingEntry,
        error: errorLoadingEntry
    } = useFetchData({
        url: '/api/entry/',
        id: paramsAccountID,
        singleValue: true,
    });

    return (
        loadingEntry ? (
            <Skeleton variant="rounded" animation="pulse" height={150} sx={{mt: 2}}/>
        ) : (
            <AccountEntryDetails accountEntry={
                {...entry, ["date"]: new Date().toISOString().slice(0, 10)}} statusHandler={props.statusHandler}/>
        )
    )
}

export default AmountEdit;