import React, {useEffect} from "react";
import AuthenticationService from "./services/authentication.service";
import {useLocation} from "react-router-dom";
import useFetchData from "./hooks/useFetchData";
import OverviewDataGrid from "./OverviewDataGrid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {useNavigate, useParams} from "react-router";
import {useConfirm} from "material-ui-confirm";

const Overview = (props) => {

    const {id: userName} = useParams(),
        navigate = useNavigate(),
        confirm = useConfirm();

    const {
        data: entries,
        loading: loadingAllEntries,
        setRefetch
    } = useFetchData({
        url: '/api/allEntries' + (userName === undefined ? '' : "/" + userName),
        statusHandler: props.statusHandler
    });

    const confirmDelete = (id) => {
        confirm({
            title: 'Löschen Bestätigen',
            description: `Möchten sie den Datensatz wirklich löschen?`,
            confirmationText: "Ja",
            cancellationText: "Abbrechen",
        })
            .then(() => deleteEntry(id))
            .catch(() => console.log("Deletion cancelled."));
    }

    const deleteEntry = (id) => {
        let token = AuthenticationService.getCurrentUser().jwt;
        fetch("/api/deleteEntry/" + id, {
            method: 'DELETE',
            headers: {'Authorization': 'Bearer ' + token}
        }).then(
            response => {
                if (response != null && response.ok) {
                    setRefetch(id);
                    return;
                }
                console.error("Request zum Löschen des Eintrages war nicht erfolgreich!");
            });
    }

    const refresh = () => {
        console.log("refresh ");
        // "refresh" only when username cleared
        navigate("/overview", {replace: true});
    }

    const location = useLocation();

    useEffect(() => {
        setRefetch(true);
    }, [location.key]);

    return (
        <Container component="main" sx={{height: "80vh"}}>
            <Typography component="h1" variant="h5" align="center" sx={{mb: 2}}>
                Übersicht Zahlungen
            </Typography>
            <OverviewDataGrid
                rows={entries}
                deleteEntry={(id) => confirmDelete(id)}
                refresh={() => refresh()}
                userName={userName}
                loading={loadingAllEntries}
            />
        </Container>
    )
}
export default Overview;