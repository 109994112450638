import {useParams} from "react-router";
import {Skeleton} from "@mui/material";
import React from "react";
import useFetchData from "./hooks/useFetchData";
import ProductDetails from "./ProductDetails";

const ProductEdit = (props) => {
    const {id: productId} = useParams();

    const {
        data: product,
        loading: loadingProduct,
        error: errorLoadingProduct
    } = useFetchData({
        url: '/api/product/',
        id: productId,
        singleValue: true,
    });

    return (
        loadingProduct ? (
            <Skeleton variant="rounded" animation="pulse" height={150} sx={{mt: 2}}/>
        ) : (
            <ProductDetails product={{
                ...product,
                value: product.value * -1,
                validFrom: product.validFrom.split('T')[0]
            }}/>
        )
    )
}

export default ProductEdit;