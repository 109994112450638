import React, {useEffect, useState} from "react";
import AuthenticationService from "./services/authentication.service";
import {useNavigate} from "react-router";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {MenuItem, Skeleton} from "@mui/material";
import {ManageAccountsRounded} from "@mui/icons-material";
import useFetchData from "./hooks/useFetchData";

const UserDetails = (props) => {
    const navigate = useNavigate(),
        [nameError, setNameError] = useState(false),
        [emailError, setEmailError] = useState(false),
        [passwordError, setPasswordError] = useState(false),
        [passwordConfirmError, setPasswordConfirmError] = useState(false),
        [user, setUser] = useState(props.user);

    const {
        data: roleOptions,
    } = useFetchData({
        url: '/api/allRoles',
        statusHandler: props.statusHandler
    });

    useEffect(() => {
        if (!AuthenticationService.getCurrentUser()) {
            navigate("/login", {state: ""});
        }

    }, []);

    const hasError = () => {
        return nameError || emailError || passwordError || passwordConfirmError;
    }

    const handleFormChange = (event) => {
        const type = event.target.type;
        const name = event.target.name;
        const value = type === "checkbox" ? event.target.checked : event.target.value;

        if (name === "roles") {
            const distinctValues = [...new Set(value)];
            const mappedRoleList = distinctValues.map((id) => {
                return roleOptions.find((role) => role.id === id);
            });
            setUser(oldState => ({...oldState, [name]: mappedRoleList}));
        } else {
            setUser(oldState => ({...oldState, [name]: value}));
        }
    }

    const validateEmail = (email) => {
        // Regular expression for a simple email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePassword = (password) => {
        // Minimum length rule
        const minLength = 8;
        if (password.length < minLength) {
            return false;
        }

        // Uppercase letters rule
        if (!/[A-Z]/.test(password)) {
            return false;
        }

        // Lowercase letters rule
        if (!/[a-z]/.test(password)) {
            return false;
        }

        // Numbers rule
        if (!/\d/.test(password)) {
            return false;
        }

        // Special characters rule
        if (!/[!?@#$%^&*,:."(){[\]}]/.test(password)) {
            return false;
        }

        // All rules passed
        return true;
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = JSON.stringify(user),
            token = AuthenticationService.getCurrentUser().jwt,
            url = user.id === 0 ? '/api/newUser' : '/api/updateUser';

        const requestOptions = {
            credentials: 'same-origin',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: data
        };

        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => console.log(data))
            .then(
                navigate("/users", {replace: true})
            )
            .catch(error => console.error);
    }

    const userRoles = user?.roles.map(role => role.id).sort();

    if (!user) {
        return (<></>);
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{m: 1, bgcolor: '#0d6efd'}}>
                    <ManageAccountsRounded/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Benutzerdetails
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                    <TextField
                        error={nameError}
                        helperText={nameError ? "Der Name darf nicht leer oder länger als 30 Zeichen sein!" : ""}
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        autoComplete="name"
                        autoFocus
                        onChange={(e) => {
                            setNameError(e.target.value.length > 30);
                            handleFormChange(e);
                        }}
                        value={user.name}

                    />
                    <TextField
                        error={emailError}
                        helperText={emailError ? "Bitte geben Sie eine gültige E-Mail Addresse ein!" : ""}
                        margin="normal"
                        required
                        fullWidth
                        name="email"
                        label="E-Mail"
                        type="email"
                        id="email"
                        autoComplete="email"
                        onChange={(e) => {
                            setEmailError(!validateEmail(e.target.value));
                            handleFormChange(e);
                        }}
                        value={user.email}
                    />
                    <TextField
                        error={passwordError}
                        helperText={passwordError ? "Das neue Passwort muss mindestens 8 Zeichen lang sein. Mindestens ein Sonderzeichen, eine Zahl, Groß- und Kleinbuchstaben enthalten!" : ""}
                        margin="normal"
                        fullWidth
                        name="password"
                        label="Passwort"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                        onChange={(e) => {
                            setPasswordError(!validatePassword(e.target.value));
                            handleFormChange(e);
                        }}
                        value={user.password}
                    />
                    <TextField
                        error={passwordConfirmError}
                        helperText={passwordConfirmError ? "Die beiden Passwörter stimmen nicht überein!" : ""}
                        margin="normal"
                        fullWidth
                        name="passwordConfirmation"
                        label="Passwort Bestätigen"
                        type="password"
                        id="passwordConfirmation"
                        autoComplete="new-password"
                        onChange={handleFormChange}
                        onBlur={() => {
                            setPasswordConfirmError(user.password !== user.passwordConfirmation);
                        }}
                        value={user.passwordConfirmation}
                    />
                    {!roleOptions ? (
                        <Skeleton variant="rounded" animation="pulse" width={396} height={56} sx={{mt: 2}}/>
                    ) : (
                        <TextField
                            select
                            label="Select Multiple Options"
                            sx={{mt: 2}}
                            name="roles"
                            id="roles"
                            multiple
                            fullWidth
                            SelectProps={{
                                variant: "outlined",
                                multiple: true,
                                value: userRoles,
                                onChange: handleFormChange
                            }}
                        >
                            {roleOptions && roleOptions.map((role) => (
                                <MenuItem key={Number(role.id)} value={role.id}>
                                    {role.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                    <Box display="flex" justifyContent="center" mt={1}>
                        <Button
                            disabled={hasError()}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2, mr: 2}}
                        >
                            Speichern
                        </Button>
                        <Button
                            type="reset"
                            variant="outlined"
                            fullWidth
                            sx={{mt: 3, mb: 2, ml: 2}}
                            onClick={() => navigate("/users")}
                        >
                            Abbrechen
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
}

export default UserDetails;
