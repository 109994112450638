import React from "react";
import {DataGrid, deDE, GridColDef} from '@mui/x-data-grid';

import AuthenticationService from "./services/authentication.service";
import {useNavigate} from "react-router";
import {ButtonGroup, IconButton, Tooltip} from "@mui/material";
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import {ManageAccountsRounded} from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/Delete';

const UserListDataGrid = (props) => {
    const navigate = useNavigate();

    const getIcons = (params) => {
        return (
            <ButtonGroup>
                {AuthenticationService.hasRole("ROLE_MANAGER") && (
                    <IconButton onClick={() => navigate("/overview/" + params.row.name)}
                                sx={{color: "#0d6efd"}}>
                        <EuroSymbolIcon/>
                    </IconButton>
                )}
                {AuthenticationService.hasRole("ROLE_MANAGER") && (
                    <IconButton onClick={() => navigate("/userEdit/" + params.id)}
                                sx={{color: "#0d6efd"}}>
                        <ManageAccountsRounded/>
                    </IconButton>
                )}
                {AuthenticationService.hasRole("ROLE_ADMIN") && (
                    <IconButton onClick={() => props.deleteEntry(params.id, params.row.name)}
                                sx={{color: "#0d6efd"}}>
                        <DeleteIcon/>
                    </IconButton>
                )}
            </ButtonGroup>
        );
    }

    const columns: GridColDef[] = [
        {field: 'name', headerName: 'Name', minWidth: 110, flex: 1.1},
        {
            field: 'email',
            headerName: 'E-Mail',
            minWidth: 120,
            flex: 1.2,
            renderCell: (params: any) => (
                <Tooltip title={params.row.email}>
                    <span>{params.row.email}</span>
                </Tooltip>
            ),
        },
        {
            field: 'aktionen',
            headerName: 'Aktionen',
            minWidth: 130,
            flex: 1.3,
            sortable: false,
            renderCell: (params) => getIcons(params),
            disableColumnMenu: true,
        }
    ];

    return (
        <DataGrid
            rows={props.rows ?? []}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: {page: 0, pageSize: 15},
                },
            }}
            pageSizeOptions={[15]}
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            loading={props.loading}
        />
    )
}

export default UserListDataGrid;