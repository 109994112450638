import React, {useState} from "react";
import EuroIcon from '@mui/icons-material/Euro';
import AuthenticationService from "./services/authentication.service";
import {useNavigate} from "react-router";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {Alert, MenuItem, Skeleton, Snackbar, useTheme} from "@mui/material";
import Container from "@mui/material/Container";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import useFetchData from "./hooks/useFetchData";

const AmountDetails = (props) => {
    const navigate = useNavigate();

    const [accountEntry, setAccountEntry] = useState(props.amountEntry),
        [open, setOpen] = useState(false),
        [amountError, setAmountError] = useState(false),
        [message, setMessage] = useState();

    const validateAmount = (amount) => {
        if (amount === ""){
            return true;
        }
        return Number(amount) > 0;
    }

    const {
        data: personOptions,
        loading: loadingPersonen,
        error: errorLoadingPersonen
    } = useFetchData({url: '/api/allUsers'});

    if (errorLoadingPersonen){
        setOpen(true);
        setMessage(errorLoadingPersonen);
    }

    const handleFormChange = (event) => {
        const type = event.target.type;
        const name = event.target.name;
        const value = type === "checkbox" ? event.target.checked : event.target.value;
        setAccountEntry(oldState => ({...oldState, [name]: value}));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = JSON.stringify(accountEntry);

        let token = AuthenticationService.getCurrentUser().jwt;

        const requestOptions = {
            credentials: 'same-origin',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: data
        };

        let url = accountEntry.id === 0 ? '/api/newEntry' : '/api/updateEntry';

        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => console.log(data))
            .then(
                navigate("/overview", {replace: true})
            )
            .catch(error => console.error);
    }

    const theme = useTheme();

    const themeWithLocale = React.useMemo(
        () => createTheme(theme, "deDE"),
        [theme],
    );

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <ThemeProvider theme={themeWithLocale}>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{m: 1, bgcolor: '#0d6efd'}}>
                    <EuroIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Einzahlung
                </Typography>
                <Box component="form" sx={{mt: 1}} onSubmit={handleSubmit}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        type={"date"}
                        id="date"
                        label="Datum"
                        name="date"
                        autoComplete="date"
                        value={accountEntry.date}
                        onChange={(e) => handleFormChange(e)}
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        multiline
                        rows={3}
                        fullWidth
                        name="text"
                        label="Text (optional)"
                        type="text"
                        id="text"
                        value={accountEntry.text}
                        onChange={(e) => handleFormChange(e)}
                    />
                    {loadingPersonen ? (
                        <Skeleton variant="rounded" animation="pulse" width={396} height={56} sx={{mt: 2}}/>
                    ) : (
                        <TextField
                            select
                            required
                            label="Person"
                            sx={{mt: 2}}
                            name="person"
                            id="person"
                            fullWidth
                            SelectProps={{
                                variant: "outlined",
                                value: accountEntry.person,
                                onChange: (e) => {
                                    // handle the person name
                                    handleFormChange(e);
                                    // handle the person itself
                                    setAccountEntry(oldState => ({
                                        ...oldState,
                                        'user': personOptions.find((p) => p.name === e.target.value)
                                    }));
                                }
                            }}
                        >
                            {personOptions && personOptions.map((person) => (
                                <MenuItem key={Number(person.id)} value={person.name}>
                                    {person.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                    <TextField
                        error={amountError}
                        helperText={"Der Betrag muss größer 0 sein!"}
                        fullWidth
                        required
                        name="amount"
                        label="Einzahlung von(€):"
                        type="number"
                        InputProps={{
                            inputProps: { min: 0 }
                        }}
                        sx={{mt: 3}}
                        id="text"
                        value={accountEntry.amount}
                        onChange={(e) => {
                            setAmountError(!validateAmount(e.target.value));
                            handleFormChange(e);
                        }}
                    />
                    <Box display="flex" justifyContent="center" mt={1}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2, mr: 2}}
                        >
                            Speichern
                        </Button>
                        <Button
                            type="reset"
                            variant="outlined"
                            fullWidth
                            sx={{mt: 3, mb: 2, ml: 2}}
                            onClick={() => navigate("/overview")}
                        >
                            Abbrechen
                        </Button>
                    </Box>
                </Box>
            </Box>
            </ThemeProvider>
            {message && (
                <Snackbar open={open} anchorOrigin={{ vertical: 'top', horizontal: 'center'}} autoHideDuration={6000} onClose={() => {
                    setMessage(null);
                    setOpen(false);
                }}>
                    <Alert severity="error" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
            )}
        </Container>
    );
}

export default AmountDetails;
