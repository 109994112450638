
const AppFooter = (props) => {
    let stateInfo = props.stateInfo;
    return (
                <footer className="fixed-bottom">
                    <div className="container text-center">
                        {/*<p>Copyright © Softwareberatung M. Höpfner</p>*/}
                    </div>
                    {/*{ stateInfo != null ? <div>{stateInfo.type + " - " + stateInfo.text}</div> : ""}*/}
                </footer>
        );
}

export default AppFooter;