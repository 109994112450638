import React, {useEffect} from "react";
import useFetchData from "./hooks/useFetchData";
import {BarChart} from "@mui/x-charts";
import Box from "@mui/material/Box";

export default function ReportMonthlyBalanceChartPerYear(props) {

    const {
        data: reportData,
        loading: loadingReport,
        setRefetch,
    } = useFetchData({
        url: '/api/reporting/balance/total/' + props.jahr,
    });

    useEffect(() => {
        setRefetch(props.jahr);
    }, [props.jahr]);

    if (!reportData) {
        return (<></>);
    }

    const data = reportData.map(obj => ({...obj, totalAmount: obj.totalAmount * -1, month: obj.month.trim()}));

    const valueFormatter = (value: number) => {
        return `${value} €`
    };

    const chartSetting = {
        xAxis: [
            {
                label: 'Betrag €',
            },
        ],
        yAxis: [
            {
                scaleType: 'band',
                dataKey: 'month',
            }
        ],
    };

    return (
        !data || data.length === 0 ? (<div>no data</div>) : (
            <Box sx={{height: "65vh", width: "100%"}}>
                <BarChart
                    dataset={data}
                    series={[
                        {dataKey: 'totalAmount', label: 'Betrag', valueFormatter},
                    ]}
                    layout="horizontal"

                    {...chartSetting}
                />
            </Box>
        )
    );
}
