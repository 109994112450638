import AccountEntryDetails from "./AccountEntryDetails";

const AccountEntryAdd = (props) => {

    return (
        <AccountEntryDetails accountEntry={{
            id: 0,
            type: 'USAGE',
            person: '',
            amount: '',
            date: new Date().toISOString().slice(0, 10),
            text: '',
        }} statusHandler={props.statusHandler}/>
    )
}

export default AccountEntryAdd;