import React, {useEffect} from "react";
import {DataGrid, deDE, GridColDef, GridToolbarContainer} from '@mui/x-data-grid';


import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import {ButtonGroup, IconButton} from "@mui/material";
import {useNavigate} from "react-router";
import AuthenticationService from "./services/authentication.service";
import EditIcon from '@mui/icons-material/Edit';

const ProductDataGrid = (props) => {
    const [rows, setRows] = React.useState(props.rows);

    const navigate = useNavigate();

    const getIcons = (params) => {
        return (
            <ButtonGroup>
                {AuthenticationService.hasRole("ROLE_MANAGER") && (
                    <IconButton onClick={() => navigate("/productEdit/" + params.id)}
                                sx={{color: "#0d6efd"}}>
                        <EditIcon/>
                    </IconButton>
                )}

                {AuthenticationService.hasRole("ROLE_ADMIN") && (

                    <IconButton onClick={() => props.deleteEntry(params.id, params.row.name)}
                                sx={{color: "#0d6efd"}}>
                        <DeleteIcon/>
                    </IconButton>
                )}
            </ButtonGroup>
        );
    }

    useEffect(() => {
        setRows(props.rows);
    }, [props.rows]);

    function EditToolbar() {
        const handleClick = () => {
            navigate("/productAdd");
        };

        return (
            <GridToolbarContainer>
                <Button color="primary" startIcon={<AddIcon/>} onClick={handleClick}
                        disabled={rows.find(row => row.id === 0) !== undefined}>
                    Produkt hinzufügen
                </Button>
            </GridToolbarContainer>
        );
    }

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 155,
            flex: 1.5,
        },
        {
            field: 'value',
            headerName: 'Kosten',
            type: "number",
            align: "right",
            headerAlign: "right",
            minWidth: 115,
            flex: 1.2,
            renderCell: (params) => {
                return new Intl.NumberFormat("de-DE", {
                    style: 'currency',
                    currency: 'EUR',
                }).format(params.value)
            },
        },
        {
            field: 'aktionen',
            headerName: 'Aktionen',
            minWidth: 100,
            flex: 1,
            sortable: false,

            renderCell: (params) => getIcons(params),
            disableColumnMenu: true,
        }
    ];

    return (
        <DataGrid
            rows={rows ?? []}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: {page: 0, pageSize: 15},
                },
            }}
            pageSizeOptions={[15]}
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            loading={props.loading}
            getRowId={(row) => row.id}
            slots={{
                toolbar: EditToolbar,
            }}
        />
    )
}

export default ProductDataGrid;