import AuthenticationService from "./authentication.service"
import {useNavigate} from "react-router";

const LoadData = async (url, statusHandler) => {
    let user = AuthenticationService.getCurrentUser();
    const navigate = useNavigate();
    let token = user ? user.jwt : null;

    if (!token || token.isEmpty) {
        const errorMessage = "Es ist keine aktive Session vorhanden. Bitte anmelden!";
        statusHandler && statusHandler.error(errorMessage);
        console.error(errorMessage);
        return;
    }

    const config = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }

    return fetch(url, config)
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    console.error("401 -> redirect to login");
                    navigate("/login");
                }
                throw Error("The request was not successful!");
            }
            return response.json();
        })
        .then(responseJson => {
            console.log(responseJson);
            return responseJson;
        })
        .catch(error => {
            // statusHandler.onError(error.message);
            console.log("API call failed " + error.message);
        });
}


export default LoadData;