import ProductDetails from "./ProductDetails";
import {getCurrentDateStringDe} from "./util/Util";

const ProductAdd = (props) => {

    return (
        <ProductDetails product={{
            id: 0,
            name: '',
            value: 0,
            validFrom: getCurrentDateStringDe(),
        }} statusHandler={props.statusHandler}/>
    )
}

export default ProductAdd;