import {useParams} from "react-router";
import {Skeleton} from "@mui/material";
import React from "react";
import useFetchData from "./hooks/useFetchData";
import UserDetails from "./UserDetails";

const UserEdit = (props) => {
    const {id: paramsAccountID} = useParams();

    console.log("ParamsId: " + paramsAccountID);

    const {
        data: entry,
        loading: loadingEntry,
    } = useFetchData({
        url: '/api/user/',
        statusHandler: props.statusHandler,
        id: paramsAccountID,
        singleData: true
    });

    return (
        loadingEntry ? (
            <Skeleton variant="rounded" animation="pulse" height={150} sx={{mt: 2}}/>
        ) : (
            <UserDetails user={entry} statusHandler={props.statusHandler}/>
        )
    )
}

export default UserEdit;