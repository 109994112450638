import React, {useState} from "react";
import {DataGrid, deDE, GridColDef, GridFilterModel} from '@mui/x-data-grid';

import AuthenticationService from "./services/authentication.service";
import {useNavigate} from "react-router";
import {ButtonGroup, IconButton, Tooltip} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import EuroIcon from '@mui/icons-material/Euro';

import {formatCurrency} from "./util/Util";

const OverviewDataGrid = (props) => {
    const navigate = useNavigate(),
        [filterModel, setFilterModel] = useState(props.userName ?
            {
                items: [
                    {
                        field: 'person',
                        operator: 'contains',
                        value: props.userName,
                    },
                ],
            } : {
                items: [],
            });

    const getIcons = (params) => {
        return (
            <ButtonGroup>
                {AuthenticationService.hasRole("ROLE_MANAGER") && Number(params.row.amount) < 0 && (
                    <Tooltip title={"Buchung bearbeiten"}>
                        <IconButton onClick={() => navigate("/accountEntryEdit/" + params.id)}
                                    sx={{color: "#0d6efd"}}>
                            <ShoppingCartIcon/>
                        </IconButton>
                    </Tooltip>
                )}

                {AuthenticationService.hasRole("ROLE_MANAGER") && Number(params.row.amount) > 0 && (
                    <Tooltip title={"Einzahlung bearbeiten"}>
                        <IconButton onClick={() => navigate("/amountEdit/" + params.id)}
                                    sx={{color: "#0d6efd"}}>
                            <EuroIcon/>
                        </IconButton>
                    </Tooltip>
                )}

                {AuthenticationService.hasRole("ROLE_ADMIN") && (
                    <Tooltip title={"Eintrag löschen"}>
                        <IconButton onClick={() => props.deleteEntry(params.id)}
                                    sx={{color: "#0d6efd"}}>
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                )}
            </ButtonGroup>
        );
    }

    const columns: GridColDef[] = [
        {
            field: 'date',
            headerName: 'Datum',
            minWidth: 110,
            flex: 1,
            valueFormatter: (params) => {
                const dateObject = new Date(params.value);

                return dateObject.toLocaleDateString('de-DE', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                });

            }
        },
        {field: 'person', headerName: 'Person', minWidth: 120, flex: 1.2},
        {field: 'text', headerName: 'Text', minWidth: 150, flex: 1.5},
        {
            field: 'amount', headerName: 'Betrag', minWidth: 120, flex: 1,
            renderCell: (params) => formatCurrency(params.row.amount * -1)
        },
        {
            field: 'aktionen',
            headerName: 'Aktionen',
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderCell: (params) => getIcons(params),
            disableColumnMenu: true,
        }
    ];

    const onFilterModelChange = (newFilters: GridFilterModel) => {
        if (
            newFilters.items[0]?.field === filterModel.items[0]?.field &&
            newFilters.items[0]?.operator === filterModel.items[0]?.operator &&
            filterModel.items[0]?.value === newFilters.items[0]?.value
        ) {
            setFilterModel({items: []});
        } else {
            setFilterModel(newFilters);
        }
    };

    return (
        <DataGrid
            rows={props.rows ?? []}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: {page: 0, pageSize: 15},
                },
                columns: {
                    columnVisibilityModel: {
                        // Hide columns status and traderName, the other columns will remain visible
                        text: false,
                    },
                },
            }}
            filterModel={filterModel}
            onFilterModelChange={(filter) => {
                onFilterModelChange(filter);
                if (filter.items.length === 0 || (filter.items[0].field === 'person' && !filter.items[0].value)) {
                    props.refresh();
                }
            }}
            pageSizeOptions={[15]}
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            //autoHeight
            //sx={{'--DataGrid-overlayHeight': '300px'}}
            loading={props.loading}
        />
    )
}

export default OverviewDataGrid;