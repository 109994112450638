import AmountDetails from "./AmountDetails";

const AmountAdd = (props) => {

    return (
        <AmountDetails amountEntry={{
            id: 0,
            person: '',
            amount: "",
            date: new Date().toISOString().slice(0, 10),
            text: '',
            type: 'PAYMENT'
        }} statusHandler={props.statusHandler}/>
    )
}

export default AmountAdd;