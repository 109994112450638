import React from "react";
import ReportMonthlyBalanceChartPerYear from "./ReportMonthlyBalanceChartPerYear";
import {MenuItem} from "@mui/material";
import TextField from "@mui/material/TextField";

export default function ReportMonthlyBalancePerYear() {
    const [jahr, setJahr] = React.useState("2022");

    const handleJahrChange = (e) => {
        setJahr(e.target.value);
    }

    return (
        <>
            <TextField
                select
                required
                label="Jahr"
                sx={{mt: 2}}
                name="jahr"
                id="jahr"
                fullWidth
                SelectProps={{
                    variant: "outlined",
                    value: jahr,
                    onChange: (e) => handleJahrChange(e)
                }}
            >
                <MenuItem key={0} value={2022}>
                    2022
                </MenuItem>
                <MenuItem key={1} value={2023}>
                    2023
                </MenuItem>
                <MenuItem key={2} value={2024}>
                    2024
                </MenuItem>

            </TextField>
            <ReportMonthlyBalanceChartPerYear jahr={jahr}/>
        </>
    )
}
