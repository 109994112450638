import React, {useState} from "react";
import AuthenticationService from "./services/authentication.service";
import {useNavigate} from "react-router";
import {Alert, MenuItem, Skeleton, Snackbar, useTheme} from "@mui/material";
import useFetchData from "./hooks/useFetchData";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Grid from "@mui/material/Grid";

const AccountEntryDetails = (props) => {
    const navigate = useNavigate();
    const [accountEntry, setAccountEntry] = useState(props.accountEntry);
    const [open, setOpen] = useState(false),
        [message, setMessage] = useState();

    const {
        data: personOptions,
        loading: loadingPersonen,
        error: errorLoadingPersonen
    } = useFetchData({url: '/api/allUsers'});

    const {
        data: productOptions,
        loading: loadingProducts,
        error: errorLoadingProducts
    } = useFetchData({url: '/api/allProducts'});

    if (errorLoadingPersonen){
        setOpen(true);
        setMessage(errorLoadingPersonen);
    }

    if (errorLoadingProducts){
        setOpen(true);
        setMessage(errorLoadingProducts);
    }

    const handleFormChange = (event) => {
        const type = event.target.type;
        const name = event.target.name;
        const value = type === "checkbox" ? event.target.checked : event.target.value;

        setAccountEntry(oldState => ({...oldState, [name]: value}));
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!accountEntry.product) {
            // Neuanlegen und Auswahl beim Produkt nicht geändert -> Daten hier setzen und ggnf bessere Stelle überlegen
            const product = productOptions[0];
            accountEntry.product = product;

            // alte Werte noch setzen -> werden noch in den reports verwendet
            accountEntry.text = product.name;
            accountEntry.amount = product.value;
        }

        const data = JSON.stringify(accountEntry);

        let token = AuthenticationService.getCurrentUser().jwt;

        const requestOptions = {
            credentials: 'same-origin',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: data
        };

        let url = accountEntry.id === 0 ? '/api/newEntry' : '/api/updateEntry';

        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => console.log(data))
            .then(
                navigate("/overview", {replace: true})
            )
            .catch(error => console.error);
    }

    const theme = useTheme();

    const themeWithLocale = React.useMemo(
        () => createTheme(theme, "deDE"),
        [theme],
    );

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <ThemeProvider theme={themeWithLocale}>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{m: 1, bgcolor: '#0d6efd'}}>
                        <ShoppingCartIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Buchung
                    </Typography>
                    <Box component="form" sx={{mt: 1}} onSubmit={handleSubmit}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            type={"date"}
                            id="date"
                            label="Datum"
                            name="date"
                            autoComplete="date"
                            value={accountEntry.date}
                            onChange={(e) => handleFormChange(e)}
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            multiline
                            rows={3}
                            fullWidth
                            name="text"
                            label="Text (optional)"
                            type="text"
                            id="text"
                            value={accountEntry.text}
                            onChange={(e) => handleFormChange(e)}
                        />
                        {loadingPersonen ? (
                            <Skeleton variant="rounded" animation="pulse" width={396} height={56} sx={{mt: 2}}/>
                        ) : (
                            <TextField
                                select
                                required
                                label="Person"
                                sx={{mt: 2}}
                                name="person"
                                id="person"
                                fullWidth
                                SelectProps={{
                                    variant: "outlined",
                                    value: accountEntry.person,
                                    onChange: (e) => {
                                        // handle the person name
                                        handleFormChange(e);
                                        // handle the person itself
                                        setAccountEntry(oldState => ({
                                            ...oldState,
                                            'user': personOptions.find((p) => p.name === e.target.value)
                                        }));
                                    }
                                }}
                            >
                                {personOptions && personOptions.map((person) => (
                                    <MenuItem key={Number(person.id)} value={person.name}>
                                        {person.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                        {loadingProducts ? (
                            <Skeleton variant="rounded" animation="pulse" width={396} height={56} sx={{mt: 2}}/>
                        ) : (
                            <TextField
                                select
                                required
                                label="Buchung von:"
                                sx={{mt: 2}}
                                name="amount"
                                id="amount"
                                fullWidth
                                SelectProps={{
                                    variant: "outlined",
                                    value: accountEntry.product?.name ?? productOptions[0]?.name,
                                    onChange: (e) => {
                                        if (productOptions && productOptions.length > 0) {
                                            const product = productOptions.find((p) => p.name === e.target.value);
                                            console.log("Produkt: " + JSON.stringify(product));
                                            setAccountEntry(oldState => ({
                                                ...oldState,
                                                'product': product,
                                                'amount': product.value
                                            }));
                                        }
                                    }
                                }}
                            >
                                {productOptions && productOptions.map((product) => (
                                    <MenuItem key={Number(product.id)} value={product.name}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={10}>
                                                {product.name}
                                            </Grid>
                                            <Grid item xs={2}>
                                                {-1 * product.value + " €"}
                                            </Grid>
                                        </Grid>
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                        <Box display="flex" justifyContent="center" mt={1}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2, mr: 2}}
                            >
                                Speichern
                            </Button>
                            <Button
                                type="reset"
                                variant="outlined"
                                fullWidth
                                sx={{mt: 3, mb: 2, ml: 2}}
                                onClick={() => navigate("/overview")}
                            >
                                Abbrechen
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </ThemeProvider>
            {message && (
                <Snackbar open={open} anchorOrigin={{ vertical: 'top', horizontal: 'center'}} autoHideDuration={6000} onClose={() => {
                    setMessage(null);
                    setOpen(false);
                }}>
                    <Alert severity="error" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
            )}
        </Container>
    );
}

export default AccountEntryDetails;
