import UserDetails from "./UserDetails";

const UserAdd = (props) => {

    return (
        <UserDetails user={{
            id: 0,
            name: '',
            email: '',
            roles: [],
            password: '',
            passwordConfirmation: ''
        }} statusHandler={props.statusHandler}/>
    )
}

export default UserAdd;