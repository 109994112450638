import React, {useEffect, useState} from "react";
import useFetchData from "./hooks/useFetchData";
import TextField from "@mui/material/TextField";
import Box from '@mui/material/Box';
import {DataGrid, GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import {IconButton} from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import Typography from "@mui/material/Typography";
import {convertDateToLocalString, formatCurrency, getCurrentDateStringDe} from "./util/Util";

export default function ReportBalanceUsers(props) {
    const [date, setDate] = React.useState(getCurrentDateStringDe());
    const [dateError, setDateError] = useState("");

    const columns: GridColDef[] = [
        {
            field: 'personName',
            headerName: 'Name',
            width: 135,
        },
        {
            field: 'totalBalance',
            headerName: 'Saldo',
            type: 'number',
            width: 125,
            align: 'right',
            renderCell: (params: GridRenderCellParams<any, String>) => (
                <Typography variant="body2"
                            color={Number(params.value) < 0 ? "red" : "green"}>{formatCurrency(params.value)}</Typography>
            ),
        },
        {
            field: 'id',
            headerName: 'Erinnerungsmail',
            width: 125,
            align: 'center',
            renderCell: (params: GridRenderCellParams<any, String>) => (
                <IconButton
                    key={params.row.id}
                    onClick={() => alert("Email Erinnerung wurde an " + params.row.personName + " gesendet!")}
                    sx={{color: "#0d6efd"}}
                    disabled={Number(params.row.totalBalance) >= 0}>
                    <EmailIcon/>
                </IconButton>
            )
        }

    ];

    const {
        data: balances,
        setRefetch,
    } = useFetchData({
        url: '/api/reporting/balance/users/',
        id: convertDateToLocalString(new Date(date)),
        singleData: true
    });

    useEffect(() => {
        if (date) {
            setRefetch(date)
        }
    }, [date]);

    if (!balances) {
        return <></>;
    }

    const data = balances.map((obj, index) => ({...obj, id: index, key: index, totalAmount: obj.totalAmount * -1}));

    return (
        <>
            <TextField
                error={dateError !== ""}
                helperText={dateError}
                InputLabelProps={{...(dateError !== "" && {shrink: true})}}
                margin="normal"
                required
                fullWidth
                type={"date"}
                id="date"
                label="Stichtag"
                name="date"
                autoComplete="date"
                value={date}
                onChange={(e) => {
                    if (!e.target.value) {
                        setDateError("Bitte geben Sie ein gültiges Datum ein!");
                    } else {
                        setDateError("");
                    }
                    setDate(e.target.value);
                }}
                autoFocus
            />
            {dateError === "" && balances &&
                <Box sx={{height: 400, width: '100%'}}>
                    <DataGrid
                        rows={data}
                        getRowId={(row) => row.key}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5]}
                        disableRowSelectionOnClick
                    />
                </Box>
            }
        </>
    )
}
