import * as React from 'react';
import {useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useGoogleLogin} from "@react-oauth/google";
import {useNavigate} from "react-router";
import AuthenticationService from "./services/authentication.service";
import {Alert, IconButton, Snackbar} from "@mui/material";
import {Google} from "@mui/icons-material";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © Softwareberatung M. Höpfner '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function Login(props) {
    const navigate = useNavigate(),
        [username, setUsername] = useState(""),
        [password, setPassword] = useState(""),
        [open, setOpen] = useState(false),
        [message, setMessage] = useState();

    const login = (e) => {
        AuthenticationService.handleLogin(e, undefined, () => {
            navigate("/")
        }, () => {
            setOpen(true);
            setMessage("Login nicht erfolgreich, bitte prüfen Sie Ihre Eingaben!");
        })
    };

    const handleGoogleLogin = useGoogleLogin({
        onSuccess: tokenResponse => {
            AuthenticationService.handleGoogleLogin(tokenResponse, () => {
                navigate("/")
            })
        },
    });
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('username'),
            password: data.get('password'),
        });
        login(event);
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{m: 1, bgcolor: '#0d6efd'}}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Login
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="E-Mail-Adresse"
                        name="username"
                        autoComplete="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Passwort"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        disabled={!(username && password)}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        Sign In
                    </Button>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={3}>
                            <IconButton onClick={() => handleGoogleLogin()} sx={{color: "#0d6efd"}}>
                                <Google/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {message && (
                <Snackbar open={open} anchorOrigin={{vertical: 'top', horizontal: 'center'}} autoHideDuration={6000}
                          onClose={() => {
                              setMessage(null);
                              setOpen(false);
                          }}>
                    <Alert severity="error" sx={{width: '100%'}}>
                        {message}
                    </Alert>
                </Snackbar>
            )}
            <Copyright sx={{mt: 8, mb: 4}}/>
        </Container>
    );
}