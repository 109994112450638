import React from "react";
import MenuIcon from '@mui/icons-material/Menu';
import AuthenticationService from "./services/authentication.service";
import {
    AppBar,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Toolbar
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router";

function AppHeader(props) {
    const drawerWidth = 240;

    const navItems = [
        {name: "Eintrag", link: "/accountEntryAdd", role: "ROLE_MANAGER"},
        {name: "Einzahlung", link: "/amountAdd", role: "ROLE_MANAGER"},
        {name: "Übersicht", link: "/overview", role: undefined},
        {name: "Benutzer Liste", link: "/users", role: "ROLE_MANAGER"},
        {name: "Produkte", link: "/products", role: "ROLE_MANAGER"},
        {name: "Mein Profil", link: "/userEdit/" + AuthenticationService.getCurrentUser()?.id, role: "ROLE_MANAGER"},
        {name: "Auswertungen", link: "/reporting", role: "ROLE_MANAGER"},
        {
            name: "Logout", link: "/logout", role: undefined, action: () => {
                AuthenticationService.setCurrentUser(null)
            }
        },
    ];

    const navigate = useNavigate();

    const currentUser = AuthenticationService.getCurrentUser();
    const {window} = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const generateKey = (pre) => {
        return `${pre}_${new Date().getTime()}`;
    }

    if (!currentUser) {
        return <></>;
    }
    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{textAlign: 'center'}}>
            <Typography variant="h6" sx={{my: 2}}>
                WinHorse
            </Typography>
            <Divider/>
            <List>
                {navItems.map((item) =>
                        (!item.role || AuthenticationService.hasRole(item.role)) && (
                            <ListItem key={generateKey(item.name)} disablePadding>
                                <ListItemButton sx={{textAlign: 'center'}} onClick={() => {
                                    if (item.action) {
                                        item.action();
                                    }
                                    navigate(item.link);
                                }}>
                                    <ListItemText primary={item.name}/>
                                </ListItemButton>
                            </ListItem>
                        )
                )
                }
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    if (!currentUser){
        return <></>;
    }
    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar component="nav">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{mr: 2, display: {sm: 'block', md: 'none'}}}

                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{flexGrow: 1, display: {xs: 'block', sm: 'block', md: 'block'}, cursor: 'pointer'}}
                        onClick={() => navigate("/")}
                    >
                        WinHorse
                    </Typography>
                    <Box sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>
                        {navItems.map((item) => (
                            (!item.role || AuthenticationService.hasRole(item.role)) && (
                                <Button
                                    key={generateKey(item.name)}
                                    sx={{color: '#fff'}}
                                    onClick={() => {
                                        if (item.action) {
                                            item.action();
                                        }
                                        navigate(item.link);
                                    }}
                                >
                                    {item.name}
                                </Button>
                            )
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: {sm: 'block'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>

        </Box>
    )
}

export default AppHeader;