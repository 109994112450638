import {useEffect, useState} from "react";
import AuthenticationService from "./services/authentication.service"

const useFetchGet = (url, callBack) => {
    const [fetchResult, setFetchResult] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    console.log("START")

    useEffect(() => {
        let user = AuthenticationService.getCurrentUser();
        if( !user || user.jwt === "")
            return;
        const requestConfig = {
            headers: {
                'Authorization': 'Bearer ' + user.jwt
            }
        }

        fetch(url, requestConfig)
            .then(response => {
                if (!response.ok) {
                    throw Error("The request was not successful!");
                }
                console.log(response);
                return response.json();
            })
            .then(data => {
                    console.log("Data: " + data instanceof Object ? JSON.parse(data) : data);
                    setFetchResult( data);
                    setLoading(false);
                    setError(null);
                    callBack({data,isLoading,error});
                }
            )
            .catch(error => {
                setLoading(false);
                setError(error.message);
            });
    }, [url]);
    // return {fetchResult, isLoading, error};
}

export default useFetchGet;