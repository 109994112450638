import React, {useEffect, useState} from "react";
import AuthenticationService from "./services/authentication.service";
import {useLocation, useNavigate} from "react-router";
import Typography from "@mui/material/Typography";
import useFetchData from "./hooks/useFetchData";
import ProductDataGrid from "./ProductDataGrid";
import {useConfirm} from "material-ui-confirm";
import Container from "@mui/material/Container";

const ProductOverview = () => {
    const navigate = useNavigate(),
        location = useLocation(),
        [update, setUpdate] = useState(false),
        confirm = useConfirm();

    const
        {
            data: entries,
            loading: loadingProducts,
            setRefetch: setRefetch,
        } = useFetchData({url: '/api/allProducts'});

    const confirmDelete = (id, name) => {
        confirm({
            title: 'Löschen Bestätigen',
            description: `Möchten sie ${name} wirklich löschen?`,
            confirmationText: "Ja",
            cancellationText: "Abbrechen",
        })
            .then(() => deleteProduct(id))
            .catch(() => console.log("Deletion cancelled."));
    }

    const deleteProduct = (id) => {
        let token = AuthenticationService.getCurrentUser().jwt;
        fetch("/api/deleteProduct/" + id, {
            method: 'DELETE',
            headers: {'Authorization': 'Bearer ' + token}
        }).then(
            response => {
                if (response != null && response.ok) {
                    setRefetch(id);
                    return;
                }
                console.error("Request zum Löschen des Eintrages war nicht erfolgreich!");
            });
    }

    useEffect(() => {
        if (!AuthenticationService.getCurrentUser()) {
            navigate("/login", {state: ""});
        }

    }, [location.key]);

    return (
        <Container component="main" maxWidth="md" sx={{height: "80vh"}}>
            <Typography component="h1" variant="h5" align="center" sx={{my: 1}}>
                Übersicht Produkte
            </Typography>
            {entries && (
                <ProductDataGrid
                    rows={entries.map(row => {
                        row.value = row.value < 0 ? row.value *= -1 : row.value;
                        return row;
                    })}
                    deleteEntry={(id, name) => confirmDelete(id, name)}
                    loading={loadingProducts}
                />
            )}
        </Container>
    );
};
export default ProductOverview;