import {useNavigate} from "react-router";
import useFetchGet from "../useFetchGet";
import useLoadData from "./api_call";

class AuthenticationService {

    navigate = () => useNavigate();
    fetch = () => useFetchGet();

    constructor() {
        const exampleResponse = {
            "jwt": "fdghdfhdfgh",
            "id": 1,
            "username": "Matthias",
            "email": null,
            "roles": ["ROLE_USER", "ROLE_MANAGER", "ROLE_ADMIN"]
        }
    }

    getCurrentUser() {
        try {
            let item = localStorage.getItem("user");
            if (!item) {
                return null;
            }

            const user = JSON.parse(item);
            const dateDiff = new Date() - new Date(user.date);

            if (dateDiff / 1000 / 60 > 150) {
                console.log("letzter Login: " + dateDiff / 1000 / 60 + " min -> auf client ausloggen");
                this.setCurrentUser(null);
                return null;
            }

            return user;
        }catch (e){
            return null;
        }
    }

    setCurrentUser(user) {
        if (!user){
            localStorage.removeItem("user");
        } else {
            localStorage.setItem("user", JSON.stringify({...JSON.parse(user), ["date"]: new Date}));
        }
    }


    async isLoggedIn() {
        return new Promise((resolve, reject) => {
            useLoadData("/api/ping").then( (data) => {
                if(!data){
                    this.setCurrentUser(null)
                    reject(false);
                }
                resolve(true);
            }).catch(reject(false));
        });
    }

    async handleLogin(e, statusHandler, loginHandler, errHandler) {
        e.preventDefault()

        const credentials = {
            'email': e.target.username.value,
            'password': e.target.password.value
        }
        const postConfig = {
            method: 'POST',
            body: JSON.stringify(credentials),
            headers: {"Content-Type": "application/json"}
        }

        console.log(JSON.stringify(postConfig));

        fetch("/api/auth/login", postConfig)
            .then(response => {
                console.log("logInHandler");
                if (!response.ok) {
                    errHandler && errHandler();
                    throw Error("The request was not successful!");
                }
                console.log(response);
                return response.json();
            })
            .then(responseJson => {
                    let user = JSON.stringify(responseJson)
                console.log("USER -> " + JSON.stringify(user));
                    this.setCurrentUser(user);
                    statusHandler?.onSuccess("Benutzer " + responseJson.username + " erfolgreich angemeldet!");
                    loginHandler();
                }
            )
            .catch(error => {
                statusHandler?.onError();
                console.log("logInHandler " + error.message);
            });
    }

    // async handleGoogleLogin(token, statusHandler, successHandler) {
    async handleGoogleLogin(token, loginHandler ) {

        const postConfig = {
            method: 'POST',
            body: JSON.stringify(token),
            headers: {"Content-Type": "application/json"}
        }

        console.log(JSON.stringify(postConfig));

        fetch("/api/auth/googleLogin", postConfig)
            .then(response => {
                console.log("logInHandler");
                if (!response.ok) {
                    throw Error("The request was not successful!");
                }
                console.log(response);
                return response.json();
            })
            .then(responseJson => {
                    let user = JSON.stringify(responseJson)
                    this.setCurrentUser(user);
                    // statusHandler.onSuccess("Benutzer " + responseJson.username + " erfolgreich angemeldet!");
                    loginHandler();
                }
            )
            .catch(error => {
                // statusHandler.onError();
                console.log("logInHandler " + error.message);
            });
    }



    hasRole(role) {
        return role && this.getCurrentUser() && this.getCurrentUser().roles?.includes(role);
    }
}

export default new AuthenticationService();