import React, {useEffect, useState} from "react";
import {Route, Routes} from "react-router-dom";
import AppFooter from "./AppFooter";
import Home from './Home'
import Overview from './Overview'
import AppHeader from "./AppHeader";
import Login from "./Login"
import './App.css';
import AuthenticationService from "./services/authentication.service"
import StatusHandler from "./services/statusHandler"
import {useNavigate} from "react-router";
import UserListView from "./UserListView";
import {ConfirmProvider} from "material-ui-confirm";
import AmountAdd from "./AmountAdd";
import AmountEdit from "./AmountEdit";
import AccountEntryAdd from "./AccountEntryAdd";
import AccountEntryEdit from "./AccountEntryEdit";
import UserEdit from "./UserEdit";
import UserAdd from "./UserAdd";
import Reporting from "./Reporting";
import {useTheme} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import ProductOverview from "./ProductOverview";
import ProductAdd from "./ProductAdd";
import ProductEdit from "./ProductEdit";
import Box from "@mui/material/Box";

const App = () => {
    console.log("START")
    const navigate = useNavigate(),
        currentUser = AuthenticationService.getCurrentUser(),
        [stateMessage, setStateMessage] = useState(
            {
                type: "",
                text: ""
            }
        ),
        [stateMessageOpen, setStateMessageOpen] = useState(false);

    useEffect(() => {
        if (!currentUser) {
            navigate("/login");
        }
    }, [currentUser]);

    const statusHandler = new StatusHandler(
        (message) => {
            setStateMessage({type: "info", text: message});
        },
        (message) => {
            setStateMessage({type: "error", text: message});
        },
        (message) => {
            setStateMessage({type: "success", text: message});
        }
    );
    const theme = useTheme();

    const themeWithLocale = React.useMemo(
        () => createTheme(theme, "deDE"),
        [theme],
    );


    return (
        <ThemeProvider theme={themeWithLocale}>
            <ConfirmProvider>
                <AppHeader/>
                <Box sx={{
                    mx: {
                        sm: 5,
                    },
                    my: {
                        xs: 9,
                        sm: 10,
                    }
                }}>
                    <Routes>
                        <Route exact path="/" element={
                            <Home/>
                        }
                        />
                        <Route path="/accountEntryAdd" element={

                            <AccountEntryAdd statusHandler={statusHandler}/>
                        }
                        />
                        <Route path="/accountEntryEdit/:id" element={

                            <AccountEntryEdit statusHandler={statusHandler}/>
                        }
                        />
                        <Route path="/productAdd" element={

                            <ProductAdd statusHandler={statusHandler}/>
                        }
                        />
                        <Route path="/productEdit/:id" element={

                            <ProductEdit statusHandler={statusHandler}/>
                        }
                        />
                        <Route path="/amountAdd"
                               element={
                                   <AmountAdd statusHandler={statusHandler}/>
                               }
                        />
                        <Route path="/amountEdit/:id" element={

                            <AmountEdit id={0} statusHandler={statusHandler}/>
                        }
                        />
                        <Route path="/overview"
                               element={

                                   <Overview statusHandler={statusHandler}/>
                               }
                        />
                        <Route path="/overview/:id" element={
                            <Overview id={0} statusHandler={statusHandler}/>
                        }
                        />
                        <Route path="/users" element={

                            <UserListView statusHandler={statusHandler}/>
                        }
                        />
                        <Route path="/userEdit/:id" element={

                            <UserEdit id={0} statusHandler={statusHandler}/>
                        }
                        />
                        <Route path="/userAdd" element={

                            <UserAdd statusHandler={statusHandler}/>
                        }
                        />
                        <Route path="/reporting" element={
                            <Reporting statusHandler={statusHandler}/>
                        }
                        />
                        <Route path="/overview/:refreshPage"
                               element={
                                   <Overview statusHandler={statusHandler}/>

                               }/>
                        <Route path="/products"
                               element={
                                   <ProductOverview statusHandler={statusHandler}/>

                               }/>
                        <Route path="/login" element={<Login/>}/>

                        <Route path="/logout" element={<Login/>}/>
                    </Routes>
                </Box>
                <AppFooter stateInfo={stateMessage}/>
            </ConfirmProvider>
        </ThemeProvider>
    )
}

export default App;
