import {useNavigate} from "react-router";
import {useEffect, useState} from "react";
import AuthenticationService from "../services/authentication.service";


const useFetchData = ({url, statusHandler, id, singleData, forceReload, queryParams}) => {
    const loadWithId = singleData ?? false;
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [refetch, setRefetchState] = useState({});
    const [reload, setReload] = useState(forceReload);

    const user = AuthenticationService.getCurrentUser();
    const navigate = useNavigate();
    const token = user ? user.jwt : null;

    let baseUrl = window.location.protocol + '//' + window.location.host;

    const urlString = new URL(baseUrl + url + (id ?? ""));

    if (queryParams) {
        Object.keys(queryParams).forEach(key => urlString.searchParams.set(key, queryParams[key]));
    }
    if (!token || token.isEmpty) {
        const errorMessage = "Es ist keine aktive Session vorhanden. Bitte anmelden!";
        console.log(errorMessage);
    }

    const method = 'GET';

    const config = {
        method: method,
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }

    useEffect(() => {

        const fetchData = async () => {
            try {
                console.log("fetchData: " + urlString.href);
                const response = await fetch(urlString.href, config);

                if (!response.ok) {
                    if (response.status === 401) {
                        console.error("ERROR: " + response.statusMessage);
                        navigate("/login");
                    }
                    throw Error("The request was not successful!");
                }

                const result = await response.json();

                setData(result);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };
        console.log("LoadData url: " + url + " id: " + id + " loadWithId: " + loadWithId);

        if (!id && loadWithId){
            console.log("LoadData canceled: id is null");
            return;
        }
        fetchData();

    }, [url, refetch, reload]);

    const setRefetch = (data) => {
        console.log("setRefetch -> " + data);
        setRefetchState(data);
    }

    return { data, loading, error, setRefetch };
}
export default useFetchData;