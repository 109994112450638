import React from "react";
import AuthenticationService from "./services/authentication.service"
import {Paper, Skeleton} from "@mui/material";
import Typography from "@mui/material/Typography";
import {convertDateToLocalString, formatCurrency} from "./util/Util";
import Grid from "@mui/material/Grid";
import useFetchData from "./hooks/useFetchData";
import {DataGrid, deDE, GridColDef} from "@mui/x-data-grid";

const Home = () => {
    const user = AuthenticationService.getCurrentUser();

    const username = user ? user.username : '';
    const {
        data: balanceData,
        loading: loadingBalance,
        setRefetch,
    } = useFetchData({
        url: '/api/reporting/balance/users/',
        id: convertDateToLocalString(new Date()),
        singleData: true
    });

    const {
        data: entries,
        loading: loadingAllEntries,
    } = useFetchData({
        url: '/api/allEntries' + (!username ? '' : "/" + username),
    });

    const columns: GridColDef[] = [
        {
            field: 'date',
            headerName: 'Datum',
            width: 95,
            valueFormatter: (params) => {
                const dateObject = new Date(params.value);

                return dateObject.toLocaleDateString('de-DE', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                });
            }
        },
        {
            field: 'product', headerName: 'Produkt', width: 150, valueGetter: (params) =>
                params.row?.product?.name
        },
        {
            field: 'amount', headerName: 'Betrag', width: 100, valueFormatter: (params) => {
                return formatCurrency(params.value * -1);
            }
        },
    ];


    const getTotalBalanceElement = () => {
        const s1 = balanceData.filter(s => s.personName === username);
        const balance = s1[0]?.totalBalance;
        return (
            <>
                <Typography component="h3" variant="h6" align="center" sx={{m: 2}}>
                    aktueller Saldo:
                </Typography>
                {isNaN(Number(balance)) ? (
                    <Typography component="h3" variant="h6" align="center" sx={{m: 2}}>
                        -
                    </Typography>
                ) : (
                    <Typography component="h3" variant="h6" align="center" sx={{m: 2}}
                                color={Number(balance) < 0 ? "red" : "green"}>
                        {formatCurrency(balance)}
                    </Typography>
                )}
            </>
        )
    }

    const getLastTreeEntries = () => {
        if (!entries) {
            return [];
        }

        if (entries.length <= 3) {
            return entries.reverse();
        }

        return entries.reverse().slice(-3).reverse();
    }

    const lastLogin = user ? new Date(user.lastLogin) : undefined;

    return (
        <Grid container={true} alignContent={"center"}>
            <Paper elevation={3} sx={{width: 350, height: 150, m: 2}}>
                <Typography component="h3" variant="h6" align="center" sx={{m: 2}}>
                    Willkommen {username}
                </Typography>
                {
                    lastLogin ? (
                        <Typography component="h3" variant="h6" align="center" sx={{m: 2}}>
                            letzter
                            Login {lastLogin ? lastLogin.toLocaleDateString() + " " + lastLogin.toLocaleTimeString() : " - "}
                        </Typography>
                    ) : (<></>)
                }
            </Paper>
            <Paper elevation={3} sx={{width: 350, height: 150, m: 2}}>
                {loadingBalance ?
                    (
                        <Skeleton variant="rounded" animation="pulse" width={250} height={56} sx={{mt: 2, py: 2}}/>
                    ) :
                    (
                        getTotalBalanceElement()
                    )
                }

            </Paper>
            <Paper elevation={3} sx={{width: 350, height: 300, m: 2}}>
                <Typography component="h3" variant="h6" align="center" sx={{m: 2}}>
                    letzte Buchungen:
                </Typography>
                <DataGrid
                    rows={getLastTreeEntries()}
                    columns={columns}
                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                    loading={loadingAllEntries}
                    hideFooter
                    disableColumnMenu
                    autoHeight
                />
            </Paper>
        </Grid>
    )
}
export default Home;