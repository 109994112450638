export function getCurrentDateStringDe() {
    return new Date().toISOString().split('T')[0];
}

export function getOneYearBeforeCurrentDateString() {
    const currentDate = new Date();

    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(currentDate.getFullYear() - 1);

    return oneYearAgo.toISOString().split('T')[0];
}

export function convertDateToLocalString(date, locale) {
    if (!date) {
        return "";
    }
    return date.toLocaleDateString(locale ? locale : 'de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
}

export function formatCurrency(value) {
    const currencyFormatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR', // Euro
    });
    return currencyFormatter.format(value);
}
